import styled from "styled-components";

export const Container = styled.div`

    .content.page-checkout.container{
        height: auto;
    }

    .checkout-items{
        display: flex;
        justify-content: space-between;
    }

    .checkout-resume-item{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 5px;
    }

    .checkout-resume-infos{
        display: flex;

        h5{
            margin-bottom: 10px;
        }

        .resume-product-infos{
            margin-left: 20px;
            margin-right: 20px;
        }

        img{
            width: 120px;
        }
    }

    .resume-product-qty{
        display: flex;
        align-items: center;

        span{
            font-size: 18px;
            margin-right: 10px;
            margin-left: 10px;
            font-weight: bold;
        }

        svg{
            width: 19px;
            height: 19px;
        }
    }

    .checkout-resume-items {
        height: 25vh;
        overflow: scroll;
        overflow-x: hidden;
    }

    .resume-footer{
        position: relative;

        .resume-checkout-total{
            display: flex;
            justify-content: space-between;
            margin-top: 3rem;
        }

        .button-finally-pay .btn_primary{
            max-width: none;
            background: ${({ theme }) => theme.colors.black};
        }
    }

    .checkout-logged{
        display: block;

        .btn_primary{
            width: 40%;
            height: 2.3rem;
            background: ${({ theme }) => theme.colors.next_first};
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    /* method payment */
    .choose-payment-method{
        display: flex;

        .type.selected{
            border-color: #40b3ff;
            background: rgba(64,179,255,0.1);
        }

        .logo svg{
            width: 50px;
            height: 50px;
        }

        .logo img{
          width: auto;
          height: 50px;
        }

        .type{
            width: 191px;
            position: relative;
            background: rgb(242, 244, 247);
            border: 2px solid rgb(232, 235, 237);
            padding: 14px;
            box-sizing: border-box;
            border-radius: 6px;
            cursor: pointer;
            text-align: center;
            transition: all 0.5s ease 0s;
            margin-right: 20px;
        }

    }

    .creditCart-form {
        margin-top: 41px;

        form#checkout-form {
            /* display: grid; */
            /* grid-template-columns: 1fr 1fr; */
        }
    }

  // mobile
  @media(max-width: 992px){

    .checkout-items{
      display: block;
      padding: 1rem;
    }

    .checkout-resume{
      margin-top: 2rem;

      .resume-product-infos h5{
          margin-top: 12px;
      }
    }

    .checkout-resume-items {
      height: 40vh;
      overflow: scroll;
      overflow-x: hidden;

      .checkout-resume-infos img {
        width: 85px;
      }

    }

    .choose-payment-method .type{
      margin: 6px;
    }



  }
`
