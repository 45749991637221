import {ProductData} from "../interfaces/ProductData";
import { createContext, useState , useEffect, ReactNode } from 'react'
import {getProducts} from "../services/api";

interface ProductContextProps {
  products: ProductData[]
  productInfos: (data: ProductData) => void
  productDataInfos: ProductData | null
}

interface ProductProviderProps{
  children: ReactNode
}

export const ProductContext = createContext({} as ProductContextProps)

export function ProductProvider({ children }: ProductProviderProps){

  const [products, setProducts] = useState<ProductData[]>([])
  const [productDataInfos, setProductInfos] = useState<ProductData | null>(null)


  useEffect(() => {
    (async () => {

        try {

          const productRequest = await getProducts()

          const [{data: productResponse}] = await Promise.all([productRequest])

          setProducts(productResponse.data)

        }catch (error){
          console.log(error)
        }

    })()

  }, [])

  const productInfos = (data: ProductData | null) => {

    setProductInfos(data)

  }

  return(

    <ProductContext.Provider value={{ products, productInfos, productDataInfos }}>
      { children }
    </ProductContext.Provider>

  )

}
