import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
   body, button, input, optgroup, select, textarea, div, a, li, span, strong{
    font-family: 'Poppins', sans-serif;
  }

  button, input{
    border-radius: 0.375rem;
  }

  .container{
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .btn_primary{
    display: block;

    background: ${({ theme }) => theme.colors.next_first};
    width: 100%;
    max-width: 13rem;
    height: 45px;
    border-radius: 8px;
    border: 0;
    margin: 1rem 0 3rem;
    font-weight: 500;
    font-size: 1.25rem;

    transition: background 0.3s;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  // form
  form{

    input{
      -webkit-box-flex: 1;
      flex-grow: 1;
      background-color: ${( { theme } ) => theme.colors.white};
      color: ${( { theme } ) => theme.colors.black};
      border: 1px solid ${( { theme } ) => theme.colors.black};
      border-radius: 4px;
      transition: all 0.2s ease 0s;
      height: 23px;
      width: 100%;
      padding: 0.7rem;
    }

    .group{
      -webkit-box-flex: 1;
      flex-grow: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 3rem;
      width: 100%;

      label{
        display: block;
        color: ${( { theme } ) => theme.colors.black};
        margin-bottom: 0.5rem;
      }

      .field{
        -webkit-box-flex: 1;
        flex-grow: 1;
        margin-bottom: 1rem;
      }
    }

    p.error{
      color: ${( { theme } ) => theme.colors.red};
    }

  }


  // product shelf
  .price_promo{
    font-weight: bold;
    font-size: 1.3rem;
    margin-left: 10px;
  }

  @media (max-width: 992px){
    .container{
      width: auto;
      padding: 0;
      margin: 0;
    }
  }

  
  
`



export const PriceOriginal = styled.div`
  font-weight: bold;
    font-size: 1.2rem;
    margin-left: 10px;
    color: ${( { theme } ) => theme.colors.next_first};
`

export const PricePromo = styled.s`
    text-decoration: line-through;
    font-size: 1rem;
    color: ${( { theme } ) => theme.colors.next_first};
`

export const PriceControl = styled.div`
    display: flex;
    align-items: center;
`