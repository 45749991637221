import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

const apiLaravel = axios.create({
  baseURL: window.location.hostname == 'localhost' ? process.env.REACT_APP_API_LARAVEL_BASE_URL : process.env.REACT_APP_API_LARAVEL_PROD_BASE_URL,
})

export const getServices = () => api.get('/')

// Banners
export const getBanners = () => apiLaravel.get('/banners')

// Shelves
export const getShelves = () => apiLaravel.get('/shelves')

// Categories
export const getCategories = () => apiLaravel.get('/categories')

// Products
export const getProducts = () => apiLaravel.get('/products')

// Coupon
export const getCoupon = () => apiLaravel.get('/coupon')

// Blog
export const getBlogPosts = () => apiLaravel.get('/blog')

export default {
  api,
  apiLaravel
}
