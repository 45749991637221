import { createContext, useState , useEffect, ReactNode } from 'react'
import { getBlogPosts } from "../services/api";
import { BlogData } from '../interfaces/BlogData';

interface BlogProps {
  blogPosts: any[]
}

interface BlogProviderProps{
  children: ReactNode
}

export const BlogContext = createContext({} as BlogProps)

export function BlogProvider({ children }: BlogProviderProps){

  const [blogPosts, setBlogPosts] = useState<[]>([])


  useEffect(() => {
    (async () => {

      try {
        
        const postsRequest = await getBlogPosts();
        const [{data: postsResponse}] = await Promise.all([postsRequest])
        setBlogPosts(postsResponse.data)

      } catch (error) {
        console.log(error)
      }

    })()
  }, [])

  return(

    <BlogContext.Provider value={{ blogPosts }}>
      { children }
    </BlogContext.Provider>

  )

}
