import styled from "styled-components";

export const Bar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 19px;
  padding: 8px 6px;
  font-size: 0.9rem;
  background: ${ ( { theme } ) => theme.colors.next_first};

  color: ${ ( { theme } ) => theme.colors.white};

`