import styled from "styled-components";

export const Container = styled.div`

  .login-account form .group{
      display: block;
      width: 50%;
      margin: 0 auto;
  }

  @media(max-width: 992px){

    .login-account form .group{
      width: auto;
    }

    .content.login-account.container{
        padding-right: 15px;
        padding-left: 15px;
    }

    form .group input {
      width: 93%;
    }

  }

`
