import styled from "styled-components";

export const Container = styled.div`

  .blog-details h1{
    font-size: 2rem !important;
  }

  @media(max-width: ${( { theme } ) => theme.breakPoints.mobile}){
    .blog-details {
      padding: 20px;
    }
  }
  
`
export const PostInfo = styled.div`

    ul{
      display: flex;
      padding-left: 0;
      
      li{
        list-style: none;
        display: flex;
        align-items: center;

        svg{
          margin-right: 5px;
        }
      }

      li + li{
        margin-left: 20px;
        
      }
    }
  
`

export const PostContent = styled.div`

    img{
      width: 100%;
    }

    p{
        
    }
  
`

