import styled from "styled-components";


export const Title = styled.h1.attrs({
    className: "page_title",
  })`
   font-family: 'Poppins', sans-serif;
   margin-top: 2rem;
   margin-bottom: 2rem;
    text-align: center;
    color: ${( { theme } ) => theme.colors.next_first};
    font-size: 1.6rem;
    font-weight: 500;
`
