import styled from "styled-components";
import {Link} from "react-router-dom";

export const Container = styled.div`

  .banner_main{
    /* margin-top: 0.6rem; */
  }

  img{
    width: 100%;
  }
`
export const Button = styled(Link)``
