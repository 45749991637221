import { BrowserRouter } from 'react-router-dom'

import { AppRoutes } from './routes'

import { Theme } from './styles/Theme'
import { GlobalStyle } from './styles/global'
import { Normalize } from 'styled-normalize'
import {ProductProvider} from "./contexts/ProductContext";
import ScrollToTop from "./Helpers/ScrollToTop";
import {CartProvider} from "./contexts/CartContext";

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {BannerProvider} from "./contexts/BannerContext";
import {ShelfProvider} from "./contexts/ShelfContext";
import {CategoryProvider} from "./contexts/CategoryContext";
import { CheckoutProvider } from './contexts/CheckoutContext'
import { AuthenticatedUserProvider } from './contexts/AuthenticatedUserContext'
import Header from './components/Header'
import Footer from './components/Footer'
import { Topbar } from './components/TopBar'
import { MaintenancePage } from './pages/MaintenancePage'
import { BlogProvider } from './contexts/BlogContext'

export default function App() {

  const underMmaintenance = true;

  return (
    
    <BrowserRouter>
      <Theme>
      {
        underMmaintenance 
        ? 
          <MaintenancePage /> 
        : 

          <AuthenticatedUserProvider>
            <CategoryProvider>
              <ProductProvider>
                <CartProvider>
                  <CheckoutProvider>
                    <ShelfProvider>
                      <BannerProvider>
                        <BlogProvider>
                        
                            <Topbar />
                            <Header />
                            
                              <ScrollToTop />
                              <AppRoutes />
                              <ToastContainer autoClose={2000} />
                              <GlobalStyle />
                              <Normalize />

                            <Footer />
                        </BlogProvider>                      
                      
                      </BannerProvider>
                    </ShelfProvider>
                  </CheckoutProvider>
                </CartProvider>
              </ProductProvider>
            </CategoryProvider>
          </AuthenticatedUserProvider>

            
        }
      </Theme>

    </BrowserRouter>
  )
}
