import styled from "styled-components";

export const Container = styled.div`

  @media(max-width: 992px){
    .products_list{
      grid-template-columns: 1fr 1fr;
    }
  }
`
