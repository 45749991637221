import styled from "styled-components";

export const Container = styled.div`

  display: flex;
  justify-content: center;
  padding: 30px;

  a{
    text-decoration: none;
    color: ${ ( { theme } ) => theme.colors.next_first };
    font-weight: bold;
  }

  // mobile
  @media(max-width: 992px){
    display: block;
    padding: 20px;

    > div{
      margin-top: 1rem;
    }
  }
`
