import styled from "styled-components";
import {Link} from "react-router-dom";

export const Container = styled.div`



  .content{
    display: block;
    text-align: center;

    .order-placed-top{
      text-align: center;

      .order-placed-msg span{
        font-size: 25px;
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
      }

      svg{
        width: 3rem;
        height: 3rem;
        margin-bottom: 1rem;
        margin-top: 3rem;
        fill: ${ ({ theme }) => theme.colors.green};
      }
    }

    .order-place-section{
      margin-top: 3rem;
    }

    .order-placed-bottom{

      span{
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .customer-information > div{
        display: grid;

        span{
          margin: 2px;
        }
      }

      .purchased-products{

        .order-product-item{
            display: grid;

          span:last-child{
            margin-bottom: 1.2rem;
          }
        }
      }
      .order-placed-bottom-footer {

        .order-payment {
          display: grid;

          span:last-child{
            margin-bottom: 1.2rem;
          }
        }

      }

    }

    .order-footer-action{
      text-align: -webkit-center;
    }
  }

`
export const Button = styled(Link)`
  display: flex !important;
  align-items: center;
  justify-content: center;
`
