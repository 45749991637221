import {ProductData} from "../interfaces/ProductData";
import {createContext, ReactNode, useState} from "react";
import {toast} from "react-toastify";

import { useNavigate } from 'react-router-dom'

interface Product extends ProductData {
  quantity: number
  subtotal: number
}

interface CartContextProps {
  cart: Product[]
  addItemToCart: (product: ProductData) => void
  removeItemFromCart: (product: Product) => void
  itemCartIncrement: (product: Product) => void
  itemCartDecrement: (product: Product) => void
  clearCart: () => void
  confirmOrder: () => void
}

interface CartProviderProps {
  children: ReactNode
}

export const CartContext = createContext({} as CartContextProps)

const localStorageKey = '@NextEcommerce:cart'

export function CartProvider({ children }: CartProviderProps) {

  const navigate = useNavigate()

  const [cart, setCart] = useState<Product[]>(() => {
    const value = localStorage.getItem(localStorageKey)

    if(value) return JSON.parse(value)

    return []
  })


  function saveCart(items: Product[]){
    setCart(items)

    localStorage.setItem(localStorageKey, JSON.stringify(items))
  }

  function clearCart(){
    localStorage.removeItem(localStorageKey)
  }


  function addItemToCart(product: ProductData): void{

    const itemExistentInCart = cart.find(
      (item) => item.id === product.id,
    )

    if(itemExistentInCart){
      toast.error(`Você já adicionou esse item ${product.name} ao carrinho  !`)
      return
    }

    // if(itemExistentInCart){

    //   const newCart = cart.map((item) => {
    //     if(item.id == product.id){
    //         const quantity = item.quantity + 1
    //         const priceControl = item.promotional_price == null ? item.original_price : item.promotional_price
    //         const subtotal = parseFloat(priceControl) * quantity

    //         return { ...item, quantity, subtotal }
    //       }

    //       return item
    //   })

    //   toast.success(`Outro(a) ${product.name} adicionado ao carrinho!`)
    //   saveCart(newCart)

    //   return

    // }

    const priceControl = product.original_price !== null && product.promotional_price !== null ? product.promotional_price : product.original_price;
    
    const newItem = { ...product, quantity: 1, subtotal: parseFloat(priceControl) }
    const newCart = [...cart, newItem] // push de um array

    toast.success(`${product.name} adicionado ao carrinho!`)
    saveCart(newCart)

  }

  function removeItemFromCart(product: Product){

    const newCart = cart.filter((item) => !(item.id === product.id))

    saveCart(newCart)

  }

  function updateSnackQuantity(product: Product, newQuantity: number){
    //
    if(newQuantity <= 0) return

    const itemExistentInCart = cart.find((item) => item.id === product.id)

    if(!itemExistentInCart) return

    const newCart = cart.map((item) => {

      
      const priceControl = item.promotional_price == null ? item.original_price : item.promotional_price

      if(item.id === itemExistentInCart.id){
        return {
          ...item, quantity: newQuantity, subtotal: parseFloat(priceControl) * newQuantity
        }
      }

      return item
    })

    saveCart(newCart)

  }

  function itemCartIncrement(product: Product) {
    updateSnackQuantity(product, product.quantity + 1)
  }

  function itemCartDecrement(product: Product) {
    updateSnackQuantity(product, product.quantity - 1)
  }

  function confirmOrder(){

    navigate('/checkout')

  }



  return(
    <CartContext.Provider
      value={{
        cart,
        addItemToCart,
        removeItemFromCart,
        itemCartIncrement,
        itemCartDecrement,
        clearCart,
        confirmOrder
      }}
    >
      {children}
    </CartContext.Provider>
  )

}
