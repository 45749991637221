import styled from "styled-components";

export const Container = styled.section`
  .products_list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .product-item{
      margin: 10px;
      margin-bottom: 30px;

      a{
        text-decoration: none;
        color: ${({ theme }) => theme.colors.black};
      }

    }

    h4{
      height: 30px;
    }

    img{
      height: auto;
      max-width: 100%;
      border: none;
      border-radius: 0;
      box-shadow: none;
    }

    button{
      margin-top: 1rem;
      background: ${({ theme }) => theme.colors.gray800};
      color: ${({ theme }) => theme.colors.white};
      width: 100%;
      height: 40px;
      border-radius: 15px;
      cursor: pointer;
      border: 0;

      :hover{
        background: ${({ theme }) => theme.colors.gray700};
      }
    }



  }

  @media(max-width: 992px){
    .products_list {
      grid-template-columns: 1fr 1fr;
    }
  }


`
