import styled from "styled-components";

export const Container = styled.div`

  div .menu-list{
    display: flex;
    gap: 20px;

    li{
      list-style: none;
    }

    li {
      a {
        font-weight: 500;
        letter-spacing: 0.12em;
        text-decoration: none;
        position: relative;

        padding: 15px 0;
        color: ${({ theme }) => theme.colors.black};
        -webkit-transition: all .3s ease-out;
        -moz-transition: all .3s ease-out;
        transition: all .3s ease-out;
        font-family: 'Poppins', sans-serif;
        font-size: 0.9rem;
        transition: 0.2s ease;

        :hover{
          color: ${({ theme }) => theme.colors.cyan};
          font-weight: bold;
        }

      }

      a::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: ${({ theme }) => theme.colors.cyan};
        transform: scaleX(0);
        transform-origin: left;
        transition: 0.2s ease;
      }

      a:hover::after { 
        transform: scaleX(1); 
      }

      a.active {
        color: ${({ theme }) => theme.colors.cyan};
        font-weight: bold;
      }
    }
  }

  @media(max-width: ${({ theme }) => theme.breakPoints.mobile}){

    .menu-list{
      li{
        a{
            color: ${({ theme }) => theme.colors.black} !important;
        }
      }

    }
  }


`

export const MenuDesktop = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  border-top: 1px solid hsl(0, 0%, 93%);
  /* background: ${({ theme }) => theme.colors.next_first}; */
`

export const MobileNav = styled.div`

  .mobile-navigation-menu {
    background: ${({ theme }) => theme.colors.white};
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    max-width: 320px;
    height: 100vh;
    padding: 20px;
    box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.1);
    overflow-y: scroll;
    overscroll-behavior: contain;
    visibility: hidden;
    transition: 0.5s ease;
    z-index: 99999;
  }

  .mobile-navigation-menu.active {
    left: 0;
    visibility: visible;
  }

  .menu-top {
    padding-bottom: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid hsl(0, 0%, 93%);
  }

  .menu-top .menu-title {
    color: ${({ theme }) => theme.colors.next_first};
    font-size: 1.125rem;
    font-weight: 600;
  }

  .menu-close-btn {
    color: ${({ theme }) => theme.colors.black};
    font-size: 22px;
  }

  .mobile-menu-category-list{
    padding: 0;
  }

  .mobile-menu-category-list .menu-category { 
    border-bottom: 1px solid hsl(0, 0%, 93%); 
    list-style: none; 
    line-height: 40px;
  }

  .mobile-menu-category-list .menu-title {
    color: ${({ theme }) => theme.colors.black};
    font-size: 0.938rem;
    font-weight: 500;
    padding: 12px 0;
    text-decoration: none;
  }



`