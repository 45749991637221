import { createContext, useState , useEffect, ReactNode } from 'react'
import { getShelves } from "../services/api";

interface ShelfContextProps {
  shelves: any[]
}

interface ShelfProviderProps{
  children: ReactNode
}

export const ShelfContext = createContext({} as ShelfContextProps)

export function ShelfProvider({ children }: ShelfProviderProps){

  const [shelves, setShelves] = useState<[]>([])

  useEffect(() => {
    (async () => {

        try {

          const shelfRequest = await getShelves()

          const [{data: shelfResponse}] = await Promise.all([shelfRequest])

          setShelves(shelfResponse.data)

        }catch (error){
          console.log(error)
        }

    })()
  }, [])

  return(

    <ShelfContext.Provider value={{ shelves }}>
      { children }
    </ShelfContext.Provider>

  )

}
