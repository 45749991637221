import styled from "styled-components";

export const Container = styled.footer`

  .footer_main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    padding-top: 60px;

    h2{
      font-size: 1.1rem;
    }

    img{
      width: 70%;
    }

    ul{
      padding: 0;
    }
    
    p{
      font-weight: 400;
      font-size: 15px;
    }

    ul li{
      list-style: none;
      margin-bottom: 15px;

      a{
        text-decoration: none;
        color: ${({ theme }) => theme.colors.black };
        font-size: 0.9rem;
      }
    }
  }

  .horario-atendimento{
    font-size: 13px;
  }

  .sociais ul{
    display: flex;
    justify-content: center;
    
    li{
      padding: 5px;

      svg{
        width: 25px;
        height: 25px;
      }
    }
  }

  .footer_main > div{
    display: block;
    text-align: center;
  }

  // mobile
  @media(max-width: 992px){
    .footer_main {
      display: block;
    }

    .footer_main > div{
      display: block;
      text-align: center;
    }
  }
`
