import styled from "styled-components";
import {Link} from "react-router-dom";

export const Container = styled.div`

    .content{
      text-align: center;
      text-align: -webkit-center;

      img{
        width: 300px;
      }
    }

  @media(max-width: 992px){

      .content img{
        width: 100% !important;
      }

  }

`
export const Button = styled(Link)`

  display: block;

  background: ${({ theme }) => theme.colors.next_first};
  width: 100%;
  max-width: 13rem;
  height: 3rem;

  border-radius: 8px;
  margin: 1rem 0 3rem;

  font-weight: 500;
  font-size: 1.25rem;
  line-height: 3rem;

  transition: background 0.3s;


  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }

`
