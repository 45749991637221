import { Container } from "./styles";

export function MaintenancePage(){
  return(
    <Container>

      <div>
        <h1>Em manutenção</h1>

        <p>Estamos passando por melhorias e correções, mas voltamos em breve.</p>
        <p>Entre em contato conosco pelo nosso Whatsapp</p>
        
        <div className="btn-whatsapp">
          <a href="https://api.whatsapp.com/send?phone=5521981855072" target="_blank" rel="noreferrer">
            Whatsapp
          </a>
        </div>
      </div>
      
    </Container>
  )
}