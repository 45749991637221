import styled from "styled-components";

export const Container = styled.div`

position: relative;

.notification-toast {
  position: fixed;
  bottom: 20px;
  left: 29%;
  right: 0;
  background: ${ ( { theme } ) => theme.colors.white};
  max-width: 300px;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding: 15px;
  border-radius: 5;
  box-shadow: 0 5px 20px hsla(0, 0%, 0%, 0.15);
  transform: translateX(calc(-100% - 40px));
  transition: 0.5s ease-in-out;
  z-index: 5;

  a{
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }

}

.notification-toast.closed { display: none; }

.toast-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${ ( { theme } ) => theme.colors.black};

  background: none;
  font: inherit;
  border: none;
  cursor: pointer;
}

.toast-banner {
  width: 70px;
  height: 70px;
  border: 1px solid hsl(0, 0%, 93%);
  border-radius: 5px;
}

.toast-banner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.toast-detail {
  width: calc(100% - 85px);
  padding-right: 10px;
}

.toast-message {
  font-size: 0.688rem;
  color: ${ ( { theme } ) => theme.colors.black};
  margin: 0;
  margin-bottom: 8px !important;
}

.toast-title {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${ ( { theme } ) => theme.colors.black};
}


`