import styled from "styled-components";

export const Container = styled.div`

  header .navBar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    height: 102px;
  }

  header .main-logo img{
    width: 250px;
  }

  .area-nav{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .area-header-icons{
    display: flex;
  }

  .area-cart a{
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    position: relative;

    margin-right: 20px;

    img{
      width: 50px;
      height: 50px;
    }

    .cart-count{
      position: absolute;
      bottom: 14px;
      left: 17px;
      border-radius: 50px;
      height: 10px;
      width: 10px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${({ theme }) => theme.colors.next_first};
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .navBar svg, .area-account svg, .header-wrap svg{
    width: 30px;
    height: 30px;
    fill: ${({ theme }) => theme.colors.black};
  }

  // dropdown account

  .account_dropdown{
    margin-right: 1rem;
    cursor: pointer;

    .open{
      display: block;
    }
  }

  .account_dropdown{
    position: relative;

    ul{

      margin: 0;
      padding: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      min-width: 160px;
      list-style: none;
      background-color: #fff;
      border: 1px solid rgba(0,0,0,0.2);
      *border-right-width: 2px;
      *border-bottom-width: 2px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
      -moz-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
      box-shadow: 0 5px 10px rgba(0,0,0,0.2);
      -webkit-background-clip: padding-box;
      -moz-background-clip: padding;
      background-clip: padding-box;


      .dropdown-button{
        background: none;
        border: 0;
        cursor: pointer;
        padding: 3px 20px;
        width: 100%;

        :hover{
          background: ${({ theme }) => theme.colors.next_first};
          color: ${({ theme }) => theme.colors.white};
        }
      }

      .dropdown-link{
        list-style: none;
        display: flex;
        justify-content: center;
        color: ${({ theme }) => theme.colors.black};
        text-decoration: none;
        padding: 3px 20px;

        :hover{
          background: ${({ theme }) => theme.colors.next_first};
          color: ${({ theme }) => theme.colors.white};
        }

      }
    }
  }

  // mobile
  @media(max-width: 992px){

    .area-cart a{
      margin-right: 0;
    }

    .header-mob-items{
      display: block;
      align-items: center;
      justify-content: space-between;
      padding-left: 0.9rem;
      padding-right: 0.9rem;

      button{
        border: none;
        background: none;
        cursor: pointer;

        svg{
          width: 25px;
          height: 25px;
        }
      }

      .logo-mobile{
        text-align: center;
      }

      .logo-mobile img{
         width: 220px;
      }
    }

  }

`
export const MenuMob = styled.div`

  .menu-list{
    display: block !important;
    padding: 0;
    padding-top: 1rem;
    margin-top: 1rem;
    margin-bottom: -10px;
    border-top: 1px solid ${( { theme } ) => theme.colors.next_first};
    border-bottom: 1px solid ${( { theme } ) => theme.colors.next_first};
    text-align: center;

    li{
      margin-bottom: 1rem;
    }

  }
`

export const Search = styled.div`

  width: 75%;
  margin-left: 40px;

  > div{
    position: relative;
  }

  input{
    background: #F3F5F6;
    border-radius: 8px;
    border: 0;
  }

  /* @media (min-width:  ${ ( { theme } ) => theme.breakPoints.desktop }  ){ */
    input{
      width: 75%;
      height: 42px;
      padding-left: 10px;
      
      ::placeholder{
        color: #737380;
        font-weight: 400;
        font-size: 14px;
      }

      &:focus{
        outline: 1px solid #737380;
      }

      &:focus-visible{
        outline: 1px solid #737380;
      }
    }

    svg{
      position: absolute;
      top: 7px;
      right: 205px;
      cursor: pointer;
    }
  /* } */

  @media (max-width:  ${ ( { theme } ) => theme.breakPoints.mobile }  ){ 

    width: auto;
    margin-left: 0;

    input{
      width: 97%;
      margin-bottom: 1rem;
      margin-top: 0.9rem;
    }

    svg{
      top: 22px;
      right: 12px;
    }

  }

  .listing_products{
    background: #f2f4f5;
    padding: 10px;
    z-index: 99;
    height: 50vh;
    overflow: scroll;

    position: absolute;
  }

  .suggestions_products a{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    text-decoration: none;
    color: ${( { theme } ) => theme.colors.black};
    
    &:hover{
      text-decoration: underline; 
    }

    img{
      width: 100px;
      margin-right: 5px;
    }
  }

`