import styled from "styled-components";

export const Container = styled.div`

  margin-top: 2rem;
  display: flex;
  justify-content: center;

  button{
    margin: 0.2rem;
    border: 0;
    border-radius: 50px;
    padding: 5px;
    width: 27px;
    cursor: pointer;

    &.active{
      background: ${ ( {theme} ) => theme.colors.next_first };
      color: ${ ( {theme} ) => theme.colors.white };
    }
  }

`