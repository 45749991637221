import { useLocation } from "react-router-dom";
import TitlePage from "../../components/TitlePage";
import {Button, Container} from "./styles";
import { useEffect, useState } from "react";
import axios from "axios";
import {useCart} from "../../hooks/useCart";

import { BsFillCheckCircleFill } from 'react-icons/bs'
import {currencyFormat} from "../../Helpers/currencyFormat";
import Loading from "../../components/Loading";
import { verifyOriginApi } from "../../Helpers/verifyOriginApi";

const apiBaseUrl = verifyOriginApi();

export default function OrderPlaced(){

    const { clearCart } = useCart()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const idOrder = queryParams.get("idPedido");
    const [order, setOrder] = useState<any>(null);

    useEffect(() => {

        clearCart()

        if (idOrder) {
            findOrder();
        }

    }, [idOrder]);

    async function findOrder() {

        try {
            const response = await axios.get(`${apiBaseUrl}/order-placed?idPedido=${idOrder}`);
            const orderData = response.data;

            setOrder(orderData);

        } catch (error) {
            console.log("Erro ao buscar o pedido:", error);
        }

    }

    if (!idOrder || !order) {
        return (
          <Loading />
        )
    }


    const customer = order.customer
    const products = order.products

    return(
        <Container>

            <div className='order-placed container'>
                <TitlePage>Meu Pedido</TitlePage>

                <div className='content'>
                    <div className='order-placed-top'>
                        <div>
                          <BsFillCheckCircleFill />
                        </div>
                      <div className='order-placed-msg'>
                        <span>Seu pedido foi efetuado com sucesso!</span>
                        <strong>ID: {order.id}</strong>
                      </div>



                    </div>

                    <div className='order-placed-bottom'>
                      <div className='customer-information order-place-section'>
                        <h2>Informações do comprador</h2>

                        <div>
                          <span>Nome: {customer.name}</span>
                          <span>Email: {customer.email}</span>
                        </div>
                      </div>

                      <div className='purchased-products order-place-section'>
                        <h2>Produtos comprados</h2>

                        <div>
                          {
                            products.map((item: any) => {

                              return(
                                <div key={item.id}>
                                  <div className='order-product-item'>
                                    <span><strong>Nome:</strong> {item.description}</span>
                                    <span><strong>Quantidade:</strong> {item.quantity}</span>
                                    <span><strong>Preço:</strong> {currencyFormat(item.amount_total / 100)}</span>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>

                      <div className='order-placed-bottom-footer order-place-section'>
                        <h2>Total</h2>

                        <div>
                          <div className='order-total'>
                            <span><strong>Total:</strong> {currencyFormat(order.amount / 100)}</span>
                          </div>

                          <div className='order-payment'>
                            <span>
                              <strong>Forma de pagamento: </strong>
                              {
                                order.payment_method_types.method == 'card'
                                  ?
                                  'Cartão de crédito'
                                  :
                                  'Boleto'
                              }
                            </span>
                            <span>
                              <strong>Status: </strong>

                              {
                                order.payment_method_types.status == 'paid'
                                  ?
                                    'Pago'
                                  :
                                    'Aguardando pagamento'
                              }
                            </span>
                          </div>

                          <div className='order-footer-action'>
                            <Button to='/minha-conta' className='btn_primary'>Ir para minha conta</Button>
                          </div>
                        </div>

                      </div>


                    </div>
                </div>
            </div>

        </Container>
    )

}
