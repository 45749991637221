import styled from "styled-components";

export const Container = styled.div`
  .infos{
    margin-left: 50px;
  }

  .price-no-promo{
    margin-top: 2rem;
    font-size: 25px;
    font-weight: bold;
  }

  .content-main{
    margin-top: 50px;
    height: auto;

    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  // button

  .buy-product{
    margin-top: 5rem;
    display: flex;

    button{
      width: 50%;
      height: 50px;
      border-radius: 15px;
      cursor: pointer;
      font-size: 20px;
      line-height: 0;
      border: 0;
      color:  ${( {theme}  ) => theme.colors.white};
      background: ${( {theme}  ) => theme.colors.black};
    }

  }


  .product-image img{
    width: 100%;
  }

  .description{
    margin-top: 50px;


    h2{
      font-size: 30px;
      margin-bottom: 40px;
      border-bottom: 4px solid ${( {theme}  ) => theme.colors.next_first};
      width: 8.5rem;
      padding-bottom: 0.2rem;
    }

    p{
      font-size: 18px;
    }
  }

  .price_promo{
    font-weight: bold;
    font-size: 1.3rem;
    margin-left: 10px;
  }

  .prices > div{
    margin-left: 0;
  }


  @media(max-width: 992px){

    .buy-product{
      button{
        width: 100%;
      }
    }

    .content{
      padding: 20px
    }

    .content-main{
      display: block;
      padding: 0;
    }

    .content-main img{
      width: 100%;
    }

    .description{
      padding: 0;
    }

    .infos{
      margin-left: 0;
    }

    .infos h1{
      font-size: 25px;
    }

    button{
      width: 100%;
    }
  }

`


