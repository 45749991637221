import styled from "styled-components";

export const Container = styled.div`

    button{
      background: none;
      font: inherit;
      border: none;
      cursor: pointer;
    }

    .mobile-bottom-navigation {
      background: ${( { theme } ) => theme.colors.white};
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 500px;
      margin: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 5px 0;
      height: 60px;
      box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.25);
      z-index: 5;
    }

    .mobile-bottom-navigation .action-btn {
      position: relative;
      font-size: 26px;
      color: ${( { theme } ) => theme.colors.black};
      padding: 10px;
    }

    .mobile-bottom-navigation .count {
      background: ${( { theme } ) => theme.colors.next_first};
      color: ${( { theme } ) => theme.colors.white};
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      padding: 5px;
      width: 9px;
      border-radius: 20px;
      text-align-last: center;
    }

  
`