import styled from "styled-components";

export const Container = styled.div`

  margin-top: 5rem;

  .platforms{
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  img{
    padding: 20px;
    width: 121px;
  }

  @media(max-width: 992px){
    .platforms{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

`