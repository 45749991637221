import styled from "styled-components";

export const Container = styled.div`

  
  
`

export const Post = styled.div`
  box-shadow: 0 0 10px 0 rgba(0,0,0,.15);
  width: 100%;

  @media(max-width: ${( { theme } ) => theme.breakPoints.mobile}){
    margin-bottom: 20px;
  }
`

export const Posts = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 21px;

    @media(max-width: ${( { theme } ) => theme.breakPoints.mobile}){
      display: block;
      padding: 20px;
    }

`

export const PostImage = styled.div`
  img{
    width: 100%;
    height: auto;
  }
`


export const PostInfo = styled.div`

  padding: 0 16px;
  padding-bottom: 16px;

  h3{
    font-size: 1rem;
    font-weight: 400;
  }

  p{
    font-size: 0.8rem;
    margin-bottom: 2rem;
  }

  a{
    text-decoration: none;
    color: ${( { theme } ) => theme.colors.black};
    
  }
`



export const ReadMe = styled.a`

  text-decoration: underline;

`