import styled from "styled-components";

export const Container = styled.div`

  .button-login{
    margin-left: 5px;
    font-weight: bold;
    text-decoration: none;
    color: ${( { theme } ) => theme.colors.next_first};

    :hover{
      font-weight: bold;
      color: ${( { theme } ) => theme.colors.red};
    }
  }

  @media(max-width: 992px){
    form .group{
      display: block;

      input{
        width: 93%;
      }
    }

    .content.create-account.container{
      padding-right: 15px;
      padding-left: 15px;
    }


  }

`

