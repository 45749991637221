import { loadStripe } from "@stripe/stripe-js";
import { createContext, ReactNode, useState } from "react";
import { useCart } from "../hooks/useCart";
import axios from "axios";
import {toast} from "react-toastify";
import {useAuthenticatedUser} from "../hooks/useAuthenticatedUser";
import { verifyOriginApi } from "../Helpers/verifyOriginApi";

interface CheckoutContextProps {
  handleCheckout: () => void
  chosenPaymentMethod: (method: string) => void
  myAccount: () => void
}

interface CheckoutProviderProps {
  children: ReactNode;
}

export const CheckoutContext = createContext({} as CheckoutContextProps);

export function CheckoutProvider({ children }: CheckoutProviderProps) {

  const { userData } = useAuthenticatedUser()

  const { cart } = useCart()

  const [paymentMethod, setPaymentMethod ] = useState('');

  const apiBaseUrl = verifyOriginApi();

  async function handleCheckout() {

    if(paymentMethod !== '') {

      const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? ""
      );

      const stripe = await stripePromise;

      if (!stripe) {
        console.log("Erro ao carregar o Stripe");
        return;
      }

      const session = await createCheckoutSession(cart);

      if (session) {
        window.location.href = session.redirectUrl;
      }

    }else{

      toast.error(`Por favor, selecione um metódo de pagamento!`)
    }

  }

  async function createCheckoutSession(cart: any) {
    
    try {
      const response = await axios.post(`${apiBaseUrl}/process-payment`, {
        cart: cart,
        emailClient: userData?.email,
        paymentMethod: paymentMethod,
        coupon: localStorage.getItem('coupon_add')
      });

      return response.data;
    } catch (error) {
      console.log("Erro ao criar a sessão de checkout:", error);
      return null;
    }
  }

  function chosenPaymentMethod(method: string){

    setPaymentMethod(method)

  }

  async function myAccount(){

    try {
      const response = await axios.post(`${apiBaseUrl}/my-account`, {
        emailClient: userData?.email,
      });

      console.log(response.data);

    } catch (error) {
      console.log("Erro ao criar a sessão de checkout:", error);
    }

    return <>a</>
  }

  return (
    <CheckoutContext.Provider
      value={{
        handleCheckout,
        chosenPaymentMethod,
        myAccount
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}
