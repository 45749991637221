import styled from "styled-components";

import bg from '../../assets/images/manutencao-bg.jpg'


export const Container = styled.div`

    
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin: 0 auto;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    
    background-image: url(${bg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;

    position: relative;

    > div{

        position: absolute;
        right: 20px;

        text-align: center;

        h1{
            margin-bottom: 2rem;
        }

        h1, p{
            padding: 0;
            font-family: 'Poppins', sans-serif;
        }

    
        .btn-whatsapp{
            margin-top: 3rem;
        }

        a{
            text-decoration: none;
            color: ${( { theme} ) => theme.colors.white};
            font-weight: bold;

            background: ${( { theme} ) => theme.colors.green};

            padding: 10px 35px;
            border-radius: 5px;
            
        }
    }

    @media(max-width: 992px){
        display: block;

        background-position: bottom;
        
        > div{

            top: 120px;
        }
    }
    
`