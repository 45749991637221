import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 32px;

    @media(min-width: ${({ theme }) => theme.breakPoints.desktop}){
        flex-direction: row;
    }

    p{
      margin: 0;
      padding: 0;
    }

`

export const CartListContainer = styled.div`
    h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 150%;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.black};
        margin-top: 24px;
    }

    p {
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.black};

        span {
            font-weight: 600;
            margin-left: 8px;
        }
    }
`

export const CartList = styled.ul`
    /* display: flex; */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    margin-top: 0;

    
    .price__control{
        margin-top: 1rem;
        /* padding-bottom: 10px;    */
        /* margin-: 20px;    */
    }
`

export const CartResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 352px;
    padding: 16px 24px;
    height: fit-content;
    padding-bottom: 40px;

    background: #F0F0F5;

    h3 {
        font-weight: 600;
        font-size: 20px;
        color: ${({ theme }) => theme.colors.black};
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    @media(max-width: ${({ theme }) => theme.breakPoints.mobile}){
        min-width: fit-content;
        margin: 1rem;
    }
`

export const TotalItem = styled.div<{ isBold: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    font-weight: ${props => props.isBold ? '600' : '400'};
    font-size: 16px;
    line-height: 150%;

    p:last-child{
        font-weight: bold;
    }

    /* margin-bottom: 12px; */
`
export const DivCoupon = styled.div`
    margin-top: 1rem;
    
    form{
        display: flex;
    }

    input{
        width: 100%;
        background: none;
        height: 20px;
        margin-top: 1rem;
        
    }

    button{
        margin-left: 1rem;
        font-size: 1rem;
    }


`

export const ShopBtn = styled.button`
    color: white;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.green};
    padding: 12px;
    width: 100%;
    border: none;
    margin-top: 40px;
    cursor: pointer;
`

// cartItem
export const Item = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;

    border-radius: 8px;
    background-color: #F0F0F5;
    
    position: relative;
    
    + li{
        margin-top: 0.5rem;
    }

    button.delete-item {
        position: absolute;
        top: 16px;
        right: 24px;

        border: none;
        background: transparent;
        cursor: pointer;
    }

    img {
        max-height: 100%;
        width: 256px;
        border-radius: 8px 0 0 8px;
    }

    > div {
        display: flex;
        width: 100%;
        height:100%;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        padding: 0 24px;
        line-height: 150%;
        color: var(--text-dark-2);

        h4 {
            font-weight: 300;
            font-size: 20px;
        }

        p {
            font-weight: 400;
            font-size: 12px;
            max-height: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        div {
            display: flex;
            align-items: center;
            width: 100%;
            margin-left: 0;
            /* padding-top: 11px; */

            span {
                font-weight: 600;
                font-size: 16px;
                color: ${({ theme }) => theme.colors.black};
            }
        }

        @media(max-width: ${({ theme }) => theme.breakPoints.mobile} ){

            h4{
                margin-top: 0;
                margin: 0;
            }

            p{
                display: none;
            }
            

            div {

                span{
                    margin-top: -2px;
                }

            }

        }
    }

    .product_item_qty {


        svg{
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .product_item_qty button {
        background: none;
        border: none;
        align-items: center;
    }

    @media(max-width: ${({ theme }) => theme.breakPoints.mobile} ){

        display: block;
        
        img{
            width: 150px;
        }

        > div {
            width: auto;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 9px;
        }
        

        button.delete-item{
            top: 0;
            bottom: 45px;
            right: 0;
        }
    }
`

export const CardItemMobile = styled.div`

    display: block;

    .cart-mobile-main{
        display: flex;
        align-items: center;
        padding: 1rem;
        position: relative;
    }

    .cart-mobile-items{
        
            margin-left: 20px;
        
            h4{
                margin: 0;
                padding: 0;
            }

            .product_item_qty {
                padding-bottom: 17px;
                padding-top: 17px;
            }
            
        .product_item_qty button {
            background: none;
            border: none;
            -webkit-box-align: center;
            align-items: center;

            svg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }

        
        }

        .price-item {
            font-weight: 600;
            font-size: 16px;
            color: rgb(12, 13, 15);
        }

        


    }

    img{
        width: 120px;
    }

    button.delete-item {
        position: absolute;
        top: 90px;
        right: 13px;
        border: none;
        background: transparent;
        cursor: pointer;
    }

`


export const Discount = styled.div`

    margin-top: 20px;

    button{
        font-size: 16px;
        height: 35px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 0;
        background: transparent;
        color: #000;
        border: 1px solid #000;

        :hover{
            background: #000;
            color: #fff;
            
        }
    }

`

